import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
// import Tilt from "react-tilt"
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa"

const query = graphql`
  {
    file(relativePath: { eq: "pages/contact/about-1.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Contact = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <Wrapper className="section-center">
      <article className="project">
        <Image fluid={fluid} className="project-img" />
        <div className="project-info contact-card">
          <div>
            <div className="contact-icon">
              <a href="tel:00447912091204">
                <FaPhone />
              </a>
            </div>
            <a href="tel:00447912091204">+44 7912 091 204</a>
          </div>
          <div>
            <div className="contact-icon">
              <a href="mailto:info@fireskilled.co.uk">
                <FaEnvelope />
              </a>
            </div>
            <a href="mailto:info@fireskilled.co.uk">info@fireskilled.co.uk</a>
          </div>
          <div className="contact-info">
            If you would like to make an appointment, simply have a question
            regarding our services or just need some advice, please do not
            hesitate to contact us. Give us a call or email us and we will get
            back to you as soon as possible.
          </div>
        </div>
      </article>
    </Wrapper>
  )
}

export default Contact

const Wrapper = styled.main`
  .contact-card {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:hover {
      box-shadow: var(--dark-shadow);
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .contact-icon a {
    font-size: 3em;
    color: var(--color-motif-1);
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }
  }
  .contact-info {
    margin-top: 2rem;
    text-align: left;
    border-top: 1px solid var(--color-motif-2);
    padding-top: 1.75rem;
  }
  a {
    font-size: 1.45rem;
    color: var(--color-primary-2);
    transition: var(--transition);
    @media screen and (max-width: 380px) {
      font-size: 1.2rem;
    }
  }
  a:hover {
    color: var(--color-motif-1);
  }

  .project {
    display: grid;
    @media screen and (min-width: 768px) {
      padding-bottom: var(--spacer-bottom);
    }
  }
  .project-img {
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    height: 20rem;
    z-index: 1;
  }
  .project-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #555, #222);
    opacity: 0;
    transition: var(--transition);
  }
  .project:hover .project-img::after {
    opacity: 0;
  }
  .project-info {
    background: var(--color-white);
    padding: 2rem 2rem;
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }
  @media screen and (min-width: 576px) {
    .project-img {
      height: 20rem;
    }
  }
  @media screen and (min-width: 768px) {
    .project-img {
      height: 24rem;
      &:after {
        opacity: 0.55;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .project {
      grid-template-columns: repeat(12, 1fr);
      align-items: center;
    }
    .project-img {
      grid-column: 1 / span 6;
      grid-row: 1 / 1;
      height: 26rem;
      border-radius: var(--radius);
      box-shadow: var(--dark-shadow);
    }
    .project-info {
      border-radius: var(--radius);
      box-shadow: var(--dark-shadow);
      z-index: 1;
      grid-column: 6 /12;
      grid-row: 1 / 1;
    }
  }
`
