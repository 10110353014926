import React from "react"
import SectionTitle from "../components/SectionTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PageWrapper from "../components/layout/PageWrapper"
import Contact from "../components/content/Contact"

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact gas fireplace installers"
      description="Contact London area gas fireplace and wood burning stoves installers"
    />
    <PageWrapper>
      <SectionTitle
        title="Contact Fire Skilled"
        strapline="Gas burners, fireplace and stoves installers"
      />
      <Contact />
    </PageWrapper>
  </Layout>
)

export default ContactPage
